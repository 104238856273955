import { registerApplication, start, LifeCycles } from 'single-spa'

registerApplication({
  name: '@cx/docs-site',
  app: DocSite,
  activeWhen: ['/'],
})

async function DocSite(): Promise<LifeCycles> {
  placeLoader()
  const app = await System.import<LifeCycles>('@cx/docs-site')
  removeLoader()
  return app
}

function placeLoader() {
  document.body.appendChild(
    Object.assign(document.createElement('div'), {
      role: 'progressbar',
      tabindex: '0',
      class: 'eds-spinner eds-spinner--large',
      id: 'single-spa-loader',
    })
  )
}

function removeLoader() {
  document.getElementById('single-spa-loader').remove()
}

start({
  urlRerouteOnly: true,
})
